import React, { useState } from 'react';
import { Avatar, Box } from '@mui/material';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Alert } from '@mui/material';
import { Container } from '@mui/material';

import { useAuth } from '../hooks/use-auth';
import { useNavigate } from 'react-router-dom';

import bkImg from '../assets/img/t3-homepage-hero@2x.webp';

const LoginForm = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')

  const handleChange = (event) => {
    const textName = event.target.name
    const textValue = event.target.value

    switch (textName) {
      case 'username':
        setUsername( textValue )
        break;
    
      case 'password':
        setPassword( textValue )
        break;
    
      default:
        break;
    }
  }
  const styles = {
    root: {
      height: '75vh',
      position: 'relative',
    },
    container: {
      position: 'relative',
      height: '100%',
      zIndex: 2,
    },
    hero: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: (theme) => theme.spacing(3),
      [ (theme) => theme.breakpoints.up('md') ]: {
        padding: (theme) => theme.spacing(6),
        paddingRight: 0,
      },
    },
    video: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      objectFit: 'contain',
      opacity: '0.15',
    },
    bkImg: {
      width: '100%',
      height: 'auto',
    },
    paper: {
      marginTop: (theme) => theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: (theme) => theme.spacing(6),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: (theme) => theme.spacing(1),
    },
    circularProgress: {
      position: 'absolute',
      color: (theme) => theme.palette.secondary.main,
      top: '50%',
      left: '50%',
      marginTop: (theme) => theme.spacing(-1),
      marginLeft: (theme) => theme.spacing(-1),
    },
    alert: {
      margin: '25px 0',
    },
    submit: {
      margin: (theme) => theme.spacing(3, 0, 2),
      textTransform: 'capitalize',
      backgroundColor: (theme) => theme.palette.secondary.main,
      color: (theme) => theme.palette.primary.main,
    },
    avatar: {
      margin: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.secondary.main,  
    },
    buttonWrapper: {
      margin: (theme) => theme.spacing(1),
      position: 'relative',
    },
  }

  const afterSignin = () => {
    // Right now there's no need to send the user back to where they started to login.
    // Just send them to the home page, when this app has more non-sales pages this maybe needed.
    // -FWW Apr 6, 2021
    // let { from } = location.state || { from: {pathname: '/home'}};
    const { from } = { from: { pathname: '/home' } };
    navigate(from, { replace: true });
  }

  const loginLoading = auth.loginLoading;
  const loginError = auth.loginError;

  const submitForm = (e) => {
    e.preventDefault();
    auth.signin(username, password)
      .then((user) => {
        if(user) {
          afterSignin();
        }
      });
  }

  return (
      <Box component="div" sx={ { ...styles.root } }>
          <Box component="div" sx={ { ...styles.container } }>
              <Box component="div" sx={ { ...styles.hero } }>
                  <Paper sx={ { ...styles.paper } }>
                      <Container maxWidth="xs">
                          <Avatar sx={ { ...styles.avatar } }>
                              <LockOutlined />
                          </Avatar>
                          <Typography component="h1" variant="h5">
                              Sign in
                          </Typography>
                          <Box component="form" sx={ { ...styles.form } } autoComplete="on" onSubmit={ e => { submitForm(e) } } noValidate>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth={ true }
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={ username }
                                onChange={ handleChange }
                              />
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth={ true }
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={ password }
                                onChange={ handleChange }
                              />
                              <Box component="div" sx={ { ...styles.buttonWrapper } }>
                                  <Button sx={ { ...styles.submit } } disabled={ loginLoading } variant="contained" type="submit">Sign In</Button>
                                  { loginLoading && <CircularProgress sx={ { ...styles.circularProgress } } size={ 24 } /> }
                              </Box>
                          </Box>
                          { loginError && <Alert sx={ { ...styles.alert } } severity="error">{ loginError.message }</Alert> }
                      </Container>
                  </Paper>
              </Box>
          </Box>
          <Box component="figure" sx={ { ...styles.video } }>
              <Box component="img" alt="several fit people around the Tonal 2" src={ bkImg } sx={ { ...styles.bkImg } } />
          </Box>
      </Box>
  )
}

export default LoginForm